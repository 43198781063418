


















import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import companiesOverviewRoutes from "../../router/companiesOverview";

@Component({ components: {} })
export default class VesselOverview extends Vue {
  private routes = companiesOverviewRoutes;
}
